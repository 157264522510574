<template>
  <div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'inventory-asset-condition-taxonomies' }"
      back-to-list-path="inventory-asset-condition-list"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/trashList`,
      }"
      @onChoose="onChoose"
    >
      <template
        #cell(actions)="{ data }"
      >
        <div
          class="d-flex justify-content-end"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LTrashIcon"
            :class="data.default ? 'd-none' : 'lightIcon cursor-pointer border-dotted featherIcon'"
            size="30"
            style="padding: 4px"
            :title="$t('Delete')"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="30"
            style="padding: 4px"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #cell(active)="data">
        {{ data.data.active ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(default)="{ data }">
        <div
          v-if="data.active"
          class="ml-2"
        >
          <span
            :id="data.id"
            class="cursor-pointer d-flex align-items-center position-relative"
            style="width: max-content; height: 20px; opacity: 0.9 "
            :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
            @click.once="setDefault(data)"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="ml-2 custom-control-group"
            >
              <b-form-radio
                v-model="data.default"
                class="mt-1"
                :aria-describedby="ariaDescribedby"
                :name="`some-radios-${data.id}`"
                :value="true"
                size="lg"
              />
            </b-form-group>
            <b-spinner
              v-if="isSettling(data.id)"
              style="margin-bottom: 4px; left: 15px; top: 4px"
              class="ml-1 position-absolute"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </span>
        </div>
        <div v-else />
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>
import {
  VBTooltip, BFormGroup, BFormRadio, BSpinner,
} from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import config from '../assetConditionConfig'

export default {
  name: 'AssetConditionList',
  components: {
    LTable,
    BFormGroup,
    BFormRadio,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected: '',
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
      draggedItem: null,
    }
  },
  computed: {
    inventoryList() {
      return this.$store.state[this.MODULE_NAME].inventoryList
    },
  },
  created() {
    this.$emit('updateMenu', 'inventory-asset-condition-list')
  },
  methods: {
    restoreContact(data) {
      this.confirmNotification(this, [data], `${this.MODULE_NAME}/restoreFromTrashList`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, { id: data.item.id, data }, `${this.MODULE_NAME}/del`)
        .then(() => {
          this.refetchData()
        })
    },
    setDefault(data) {
      const { id } = data
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      ).then(() => {
        this.refetchData()
        this.isLoadingOfSetDisabled = false
      })
    },
    onChoose(item) {
      const { moved: { element: { id }, newIndex } } = item

      this.sendNotification(
        this, { id: Number(id), position: Number(newIndex) },
        `${this.MODULE_NAME}/setPosition`,
      ).then(() => {
        this.refetchData()
        this.isLoadingOfSetDisabled = false
      })
    },
    duplicate(data) {
      this.$refs.lTableRef.isBusy = true
      this.$store.dispatch(`${this.MODULE_NAME}/duplicate`, data).then(() => {
        this.refetchData()
      }).catch(() => {
        this.$refs.lTableRef.isBusy = false
      })
    },
  },
  setup() {
    const MODULE_NAME = 'asset-condition-taxonomies'
    const { tableColumns, fields, autoSuggestTableColumns } = config()

    return {
      MODULE_NAME,
      fields,
      tableColumns,
      autoSuggestTableColumns,
    }
  },
}
</script>

<style scoped>

</style>
